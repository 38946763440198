<template>
    <aside class="flex gap-1 flex-column align-items-start surface-100 py-4 px-2 mt-7 w-25rem">
        <div class="w-full flex flex-column gap-1 mt-5 p-2 select-none">
            <div class="w-full flex flex-column gap-1" v-for="(item, index) in menu">
                <div
                    class="flex gap-3 justify-content-between align-items-center border-round cursor-pointer hover:bg-blue-100 overflow-hidden"
                    @click="() => openMenuChildren(index)"
                >
                    <RouterLink
                        v-if="item.routerName"
                        :to="{ name: item.routerName }"
                        class="w-full flex flex-auto gap-3 align-items-center p-3"
                    >
                        <span :class="item.icon"></span>
                        <span>{{ item.label }}</span>
                    </RouterLink>
                    <div v-else class="flex flex-auto gap-3 align-items-center p-3">
                        <span :class="item.icon"></span>
                        <span>{{ item.label }}</span>
                    </div>
                    <span
                        class="pr-4 pi transition-duration-200"
                        :class="{ 'pi-angle-down': item.isOpen, 'pi-angle-right': !item.isOpen }"
                        v-if="item.children"
                    ></span>
                </div>
                <template v-if="item.children && item.isOpen">
                    <div
                        class="ml-5 flex gap-3 justify-content-between align-items-center border-round cursor-pointer hover:bg-blue-100 overflow-hidden"
                        v-for="children in item.children"
                    >
                        <RouterLink :to="{ name: children.routerName }" class="flex flex-auto gap-3 align-items-center p-3">
                            <span :class="children.icon"></span>
                            <span>{{ children.label }}</span>
                        </RouterLink>
                    </div>
                </template>
            </div>
        </div>
    </aside>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/User";
import { getMenuByRole } from "../model";

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const route = useRoute();

const menu = ref([]);

function openMenuChildren(index) {
    menu.value[index].isOpen = !menu.value[index].isOpen;
}

onMounted(() => {
    const array = getMenuByRole(userData.value.role);

    for (const row of array) {
        if (!row.children) continue;

        for (const rowChildren of row.children) {
            if (rowChildren.routerName === route.name) {
                row.isOpen = true;
            }
        }
    }
    menu.value = array;
});
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
.router-link-active {
    @include styleclass("bg-blue-100 text-blue-500");
}
</style>
