<template>
    <RouterView></RouterView>
    <Toast />
    <DynamicDialog />
    <ConfirmDialog class="w-3" :draggable="false">
        <template #message="{ message }">
            <span v-html="message.message"></span>
        </template>
    </ConfirmDialog>
</template>
<script setup>
import { onMounted } from "vue";

import Toast from "primevue/toast";
import DynamicDialog from "primevue/dynamicdialog";
import ConfirmDialog from "primevue/confirmdialog";
import { usePrimeVue } from "primevue/config";

import { PRIME_LOCALES } from "@/shared/config";

function changeLocaleData() {
    const primevue = usePrimeVue();

    Object.keys(PRIME_LOCALES).forEach(key => {
        primevue.config.locale[key] = PRIME_LOCALES[key];
    });
}

onMounted(() => {
    changeLocaleData();
});
</script>
