import { routerNames } from "@/app/providers/router";
import { USER_ROLE } from "@/shared/lib/types";
import { PrimeIcons } from "primevue/api";

const menuAdmin = [
    {
        label: "Закупки",
        icon: "pi pi-shopping-bag",
        children: [
            {
                label: "Обложки",
                routerName: routerNames.ADMIN_PURCHASE_COVER,
                icon: PrimeIcons.IMAGE,
            },
            {
                label: "Привязка карт",
                routerName: routerNames.ADMIN_CART_BINDING,
                icon: PrimeIcons.CREDIT_CARD,
            },
        ],
    },
    {
        label: "Оплаты",
        icon: "pi pi-dollar",
        children: [
            {
                label: "Проверка оплат",
                routerName: routerNames.ADMIN_PAYMENTS,
                icon: PrimeIcons.HOURGLASS,
            },
            {
                label: "Оплаты на карту",
                routerName: routerNames.ADMIN_PAYMENTS_TO_CARD,
                icon: PrimeIcons.CREDIT_CARD,
            },
            {
                label: "Выгрузка оплаты",
                routerName: routerNames.ADMIN_EXPORT_PAYMENT,
                icon: PrimeIcons.FILE_EXPORT,
            },
        ],
    },
    {
        label: "Бонусная программа",
        icon: "pi pi-book",
        children: [
            {
                label: "Настройки",
                icon: "pi pi-cog",
                routerName: routerNames.ADMIN_BONUS_SETTINGS,
            },
            {
                label: "Исключения",
                icon: PrimeIcons.USER_MINUS,
                routerName: routerNames.ADMIN_BONUS_EXCEPTION,
            },
            {
                label: "Ручное начисление",
                icon: PrimeIcons.WAVE_PULSE,
                routerName: routerNames.ADMIN_BONUS_UPLOAD,
            },
        ],
    },
    {
        label: "Отчеты",
        icon: PrimeIcons.FILE_EXCEL,
        children: [
            {
                label: "Клиенты",
                icon: PrimeIcons.USERS,
                routerName: routerNames.ADMIN_REPORT_CLIENTS,
            },
        ],
    },
];

const menuStorekeeper = [
    {
        label: "Главная",
        icon: PrimeIcons.HOME,
        routerName: routerNames.STOREKEEPER_HOME,
    },
];

export function getMenuByRole(role) {
    const roles = {
        [USER_ROLE.ADMIN]: menuAdmin,
        [USER_ROLE.STOREKEEPER]: menuStorekeeper,
    };

    if (roles[role]) {
        return roles[role];
    }

    return [];
}
